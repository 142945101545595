export const dataFeedList = [
  {
    title: "Client Portfolio Master",
    list: [
      {
        title: "Clients",
        isSupported: "Coming Soon"
      },
      {
        title: "Accounts",
        isSupported: "Coming Soon"
      },
      {
        title: "Portfolios",
        isSupported: "Coming Soon"
      },
      {
        title: "Benchmarks",
        isSupported: "Coming Soon"
      }
    ]
  },
  {
    title: "Security Master",
    list: [
      {
        title: "Issuers/Counterparties",
        isSupported: "Coming Soon"
      },
      {
        title: "Securities",
        isSupported: "Coming Soon"
      },
      {
        title: "Security Analytics",
        isSupported: "Coming Soon"
      },
      {
        title: "Prices",
        isSupported: "Coming Soon"
      },
      {
        title: "Factors",
        isSupported: "Coming Soon"
      },
      {
        title: "Ratings",
        isSupported: "Coming Soon"
      },
      {
        title: "Put/Call/Sink Schedules",
        isSupported: "Coming Soon"
      }
    ]
  },
  {
    title: "Position Master",
    list: [
      {
        title: "Holdings",
        isSupported: "Coming Soon"
      },
      {
        title: "Purchase Lots",
        isSupported: "Coming Soon"
      },
      {
        title: "Transactions",
        isSupported: "Coming Soon"
      },
      {
        title: "Benchmark Constituents",
        isSupported: "Coming Soon"
      },
      {
        title: "Custom/Blended Benchmarks",
        isSupported: "Coming Soon"
      }
    ]
  },
  {
    title: "Performance & Risk",
    list:  [
      {
        title: "Performance Returns",
        isSupported: "Coming Soon"
      },
      {
        title: "Benchmark Returns",
        isSupported: "Coming Soon"
      },
      {
        title: "Portfolio Risk",
        isSupported: "Coming Soon"
      },
      {
        title: "Attribution",
        isSupported: "Coming Soon"
      }
    ]
  }
]
