import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import Introduction from "../../components/Introduction"
import DataFeed from "../../sections/technology/DataFeed"
import NextService from "../../sections/services/NextService"

import commonDataElementsBgImg from "../../../media/common-data-elements-banner-overlay.jpg"

const title = "Rivvit Supports Common Data Elements"
const description = ""
const introductionTitle = "To onboard your data fast, Rivvit contains built-in data models for data elements that are common among financial services businesses."
const introductionPara = "By providing a single source of truth for client portfolios, securities, positions, transactions, and risk data, Rivvit provides the foundation to produce outputs required for client reporting, compliance, performance, attribution, and risk oversight. Driven by client demand, our development roadmap is constantly updated to include new and changing data sets and is flexible to accommodate custom requests.  Below is a list of the foundational data we currently support for financial services clients."
const para = [
  introductionPara
]

const CommonDataElements = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={title} />
    <SubHero
      backgroundImage={commonDataElementsBgImg}
      title={title}
      description={description}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <DataFeed />
    <NextService
      text="Request a demo of Rivvit"
      link="contact-us"
    />
  </Layout>
)

export default CommonDataElements
