import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 60,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    textAlign: "center",
    marginBottom: 20,
  },
  dataFeedImg: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  tableContainer: {
    borderRadius: 0,
    marginTop: 30,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  table: {
    //
  },
  subTableHead: {
    height: 40,
    background: "#2d65b2",
  },
  subTableHeadText: {
    color: "#fff",
    fontSize: 18,
  },
  contentRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableHeaderCell: {
    padding: "10px 15px"
  },
  tableCell: {
    width: "50%",
    padding: "2px 15px",
  },
  tableCellText: {
    fontSize: 18,
    padding: 5,
    
  },
  iconButton: {
    color: "#06d6a0",
  },
}))
