import * as React from "react"
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"

import { dataFeedList } from "./data"
import { useStyles } from "./styles"

const introductionText = ""

const DataFeed = () => {
  const classes = useStyles()

  const isSupported = (cell: string) => {
    if (cell === "No") {
      return false
    } else {
      return true
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="body2" className={classes.description}>
          {introductionText}
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          {dataFeedList.map((row: any, index) => (
            <Table key={`data-feed-table-${index}`} className={classes.table}>
              <TableHead className={classes.subTableHead}>
                <TableRow>
                  <TableCell align="left" className={classes.tableHeaderCell}>
                    <Typography variant="subtitle2" className={classes.subTableHeadText}>
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeaderCell} />
                </TableRow>
              </TableHead>
              <TableBody>
              {row.list.map((lItem: any, lIndex: number) => (
                <TableRow key={`data-feed-table-section-${index}-${lIndex}`} className={classes.contentRow}>
                  <TableCell align="left" className={classes.tableCell}>
                    <Typography variant="body2" className={classes.tableCellText}>
                      {lItem.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Typography variant="body2" className={classes.tableCellText}>
                      <div className={classes.iconButton}>
                        {isSupported(lItem.isSupported) &&
                          <CheckIcon />
                        }
                        {!isSupported(lItem.isSupported) &&
                          <CloseIcon />
                        }
                      </div>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          ))}
        </TableContainer>
      </div>
    </div>
  )  
}

export default DataFeed
